import {
  FilteredRenderPass,
  RenderingPolicy,
  SceneFilterFunction,
  SubScenePipeline,
} from "@faro-lotv/lotv";
import { UPDATE_CAMERA_MONITOR_PRIORITY } from "@faro-lotv/spatial-ui";
import { useFrame, useThree } from "@react-three/fiber";
import { PropsWithChildren, forwardRef, useEffect, useState } from "react";
import { TypedEventCallback, useTypedEvent } from "../hooks/use-typed-event";
import { attachSubScene } from "./attach-utils";

export type SubSceneProps = PropsWithChildren<{
  /** Function to select from the scene graph only the elements to be rendered in this subscene. */
  filter?: SceneFilterFunction;
  /** Whether to clear the depth buffer of the output FBO before rendering this pass */
  clearDepths?: boolean;
  /** An object encapsulating rendering policy logics */
  renderingPolicy?: RenderingPolicy;
  /** Whether the subscene is enabled */
  enabled?: boolean;
  /** Callback to be called just before the main rendering pass of the subscene is called */
  onBeforeRender?: TypedEventCallback<FilteredRenderPass["beforeRender"]>;
  /** Make the background of the framebuffer completely transparent */
  transparentBackground?: boolean;
}>;

export type SubSceneRef = SubScenePipeline | undefined;

/**
 * @returns A filtered sub-scene part of a EffectPipelineWithSubScenes
 */
export const SubScene = forwardRef<SubSceneRef, SubSceneProps>(
  function SubScene(
    {
      children,
      clearDepths = false,
      filter = () => true,
      renderingPolicy,
      enabled = true,
      onBeforeRender,
      transparentBackground = false,
    }: SubSceneProps,
    ref,
  ): JSX.Element {
    const gl = useThree((s) => s.gl);
    const scene = useThree((s) => s.scene);
    const camera = useThree((s) => s.camera);

    const [subScene] = useState(
      () => new SubScenePipeline(gl, scene, camera, filter),
    );

    useEffect(() => {
      subScene.changeCamera(camera);
    }, [subScene, camera]);

    subScene.renderPass.transparentBackground = transparentBackground;

    useTypedEvent(subScene.renderPass.beforeRender, onBeforeRender);

    // When the 'renderingPolicy' prop is changed by the caller,
    // the subScene object should be assigned to it. In this way
    // the rendering policy can e.g. invalidate the subscene when
    // needed, enable/disable single effects, and apply other logic.
    useEffect(() => {
      if (renderingPolicy) renderingPolicy.subScene = subScene;
      return () => {
        if (renderingPolicy) renderingPolicy.subScene = undefined;
      };
    }, [renderingPolicy, subScene]);

    useEffect(() => {
      subScene.clearDepths = clearDepths;
    }, [subScene, clearDepths]);

    useFrame((_, delta) => {
      // At each frame, this component evaluates whether the camera
      // pose or e.g. the point cloud pose changed since the last check
      // This information is then processed by the camera monitor to issue signals
      // about whether the camera or the scene are moving or whether everything is
      // still.
      subScene.cameraMonitor.checkCameraMovement(camera, delta);
      if (renderingPolicy?.sceneChanged()) {
        subScene.invalidate();
      }
      // Calling this hook with UPDATE_CAMERA_MONITOR_PRIORITY so that it is
      // executed after the controls have moved the camera and before LOD visibility
      // computations happen.
    }, UPDATE_CAMERA_MONITOR_PRIORITY);

    return (
      <primitive
        object={subScene}
        ref={ref}
        attach={attachSubScene}
        /* Properly update the sub-scene when the filter changes */
        filter={filter}
        enabled={enabled}
      >
        {children}
      </primitive>
    );
  },
);
