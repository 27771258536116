import { TypedEvent, assert } from "@faro-lotv/foundation";
import {
	Box2,
	BufferGeometry,
	Camera,
	ClampToEdgeWrapping,
	DataTexture,
	DoubleSide,
	FloatType,
	Group,
	Intersection,
	Matrix4,
	Mesh,
	NearestFilter,
	Object3D,
	RGBAFormat,
	Raycaster,
	Scene,
	Texture,
	UVMapping,
	Vector2,
	WebGLRenderer,
} from "three";
import { PanoTileMaterial } from "../Materials/PanoTileMaterial";
import { sphereGeometry } from "../Utils/GeometricUtils";
import { EquirectangularDepthImage } from "./EquirectangularDepthImage";
import { Pano } from "./Pano";

const DEFAULT_RECT = new Box2(new Vector2(0, 0), new Vector2(1, 1));

const DUMMY_TEXTURE = new Texture();

/**
 * Convert a scalar in the [-1, 1] range to an angle in the [0, -2 * PI] range
 *
 * @param u The input scalar to convert
 * @returns The output angle, in radians
 */
export function uToPhi(u: number): number {
	// u should be between -1 and 1 (as ndc) and map 0 to -360
	return -Math.PI * (u + 1);
}

/**
 * Convert a scalar in the [-1, 1] range to an angle in the [-PI, PI] range
 *
 * @param v The input scalar to convert
 * @returns The output angle, in radians
 */
export function vToTheta(v: number): number {
	// v should be between -1 and 1 (as ndc) and map -90 to 90
	return Math.PI * v * 0.5;
}

/**
 * Compute the sphere slice of a specific tile, based on the rect of the tile
 *
 * @param rect The 2D bounding box of the tile with respect to the full scene equirectangular texture.
 * @returns The buffer geometry of the specific tile
 */
function computeGeometry(rect: Box2): BufferGeometry {
	const phi0 = uToPhi(rect.min.x * 2 - 1);
	const phi1 = uToPhi(rect.max.x * 2 - 1);
	const theta0 = vToTheta(rect.min.y * 2 - 1);
	const theta1 = vToTheta(rect.max.y * 2 - 1);
	return sphereGeometry(
		1,
		4,
		4,
		Math.min(phi0, phi1),
		Math.abs(phi0 - phi1),
		Math.min(theta0, theta1),
		Math.abs(theta0 - theta1),
	);
}

/**
 * An object used to render a single tile of a pano tiled image
 */
export class PanoTile extends Mesh<BufferGeometry, PanoTileMaterial> {
	beforeRender = new TypedEvent<void>();

	override name = "PanoTile";

	/**
	 * Construct a Pano Tile
	 *
	 * @param material The material used to render this Image
	 * @param texture The texture used for this tile
	 * @param rect The rect in uv coordinate that this tile cover of the entire pano sphere
	 */
	constructor(
		material: PanoTileMaterial,
		public texture?: Texture,
		public rect = DEFAULT_RECT.clone(),
	) {
		super(computeGeometry(rect), material);
		this.material.depthTest = false;
		this.material.depthWrite = false;
		this.material.side = DoubleSide;
		this.material.name = `PanoTileMaterial : ${texture?.name ?? ""}`;
		this.geometry.name = `PanoTileGeometry : ${texture?.name ?? ""}`;
	}

	/**
	 * set the values of this tile
	 *
	 * @param texture The texture used for this tile
	 * @param rect The rect in uv coordinate that this tile cover of the entire pano sphere
	 * @returns the previous tile if present
	 */
	setTile(texture: Texture, rect = DEFAULT_RECT.clone()): Texture | undefined {
		const prev = this.texture;
		this.texture = texture;
		this.rect = rect;
		this.material.name = `PanoTileMaterial : ${texture.name}`;
		this.geometry.name = `PanoTileGeometry : ${texture.name}`;
		return prev;
	}

	/**
	 * Dispose geometry used for this pano tile.
	 * The texture disposal is not managed by this object.
	 */
	dispose(): void {
		this.geometry.dispose();
	}

	/**
	 * Update the materials and fetch data from the backend before rendering this object.
	 *
	 * @inheritdoc
	 */
	override onBeforeRender = (renderer: WebGLRenderer, scene: Scene, camera: Camera): void => {
		if (this.texture) {
			this.material.setTile(this.texture, this.rect);
		} else {
			this.material.setTile(DUMMY_TEXTURE, DEFAULT_RECT);
		}
		this.material.update(this.matrixWorld, camera, renderer);
		this.beforeRender.emit();
	};

	/** @inheritdoc */
	override raycast(): void {}
}

/**
 * A pano image composed by multiple tiles of an equirectangular image
 * It's composed by an overview tile that cover the entire sphere and multiple tiles
 * It's possible to add more tiles, new tiles will write over previous tiles
 */
export class TiledPano extends Group implements Pano {
	override name = "TiledPano";
	material = new PanoTileMaterial();
	/** Data texture to keep the depth information */
	depths?: EquirectangularDepthImage;
	/** Signal to notify when the depth texture have changed */
	depthsChanged = new TypedEvent<void>();
	/** Signal to notify when the color texture have changed */
	textureChanged = new TypedEvent<void>();
	/** Signal to notify that the object is about to be rendered */
	beforeRender = new TypedEvent<void>();
	/** Signal to notify that this object have been disposed */
	disposed = new TypedEvent<void>();
	/** If true will delay changes that may slow down rendering like uploading big textures */
	#animating = false;
	/** A new overview tile that we're waiting to add during animations */
	#pendingTile?: Texture;
	/** The overview tile */
	#overviewTile: PanoTile;
	/** The list of all the textures added to this object */
	#textures: Texture[] = [];
	/** A color image that represent depth data associated with this pano */
	#depthImage?: Texture;
	/** The most recent overview image of the pano. */
	#colorOverviewImage?: Texture;

	/** This group will only contains PanoTiles */
	override children: PanoTile[] = [];

	/** Computes a color texture that represents the depth information. */
	#computeDepthImage(): void {
		if (!this.depths) return;
		const { height, width } = this.depths.image;
		const N = width * height;
		// TODO: find a better way to estimate the depth distribution
		// and find a more appropriate value for 'maxDepth'
		const maxDepth = 10;
		const minColor = 0.2;
		const maxDepthInv = (1.0 - minColor) / maxDepth;
		const buffer = new Float32Array(4 * N);
		for (let row = 0; row < height; ++row) {
			for (let col = 0; col < width; ++col) {
				const i = width * row + col;
				const o = width * (height - 1 - row) + col;
				const depth = this.depths.depth(i);
				const c = depth === 0 ? 0 : depth * maxDepthInv + minColor;
				buffer[4 * o] = buffer[4 * o + 1] = buffer[4 * o + 2] = c;
				buffer[4 * o + 3] = 1.0;
			}
		}
		this.#depthImage = new DataTexture(
			buffer,
			width,
			height,
			RGBAFormat,
			FloatType,
			UVMapping,
			ClampToEdgeWrapping,
			ClampToEdgeWrapping,
			NearestFilter,
			NearestFilter,
		);
		this.#depthImage.needsUpdate = true;
	}

	/**
	 * @returns the number of tiles active in this pano
	 */
	get numTiles(): number {
		return this.children.length;
	}

	/**
	 * Create a tiled pano, the first texture is the full, low res, equirectangular image
	 *
	 * @param texture The full low res pano to start with (will cover the entire sphere surface)
	 * @param position The 3d position of this object
	 */
	constructor(
		public texture?: Texture,
		position = new Matrix4(),
	) {
		super();
		this.applyMatrix4(position);
		this.#overviewTile = new PanoTile(this.material);
		if (texture) {
			this.#textures.push(texture);
			this.#overviewTile.setTile(texture)?.dispose();
			this.name = `TiledPano ${texture.name || texture.uuid}`;
		}
		this.#overviewTile.beforeRender.pipe(this.beforeRender);
		this.clear();
	}

	/**
	 * Clears all the child tiles and reverts back to the base image
	 *
	 * @returns this
	 */
	override clear(): this {
		super.clear();
		this.add(this.#overviewTile);
		return this;
	}

	/**
	 * Dispose all resources used by this object, the plane geometry, the depths if attached and all the textures
	 *
	 * @param shouldDisposeTextures true to dispose of the textures
	 */
	dispose(shouldDisposeTextures = true): void {
		for (const child of this.children) {
			child.dispose();
		}
		this.material.dispose();
		if (shouldDisposeTextures) {
			for (const texture of this.#textures) {
				texture.dispose();
			}
			this.#textures.length = 0;
		}
		if (this.depths) {
			this.depths.dispose();
		}
		if (this.#depthImage) {
			this.#depthImage.dispose();
		}
		this.disposed.emit();
	}

	/**
	 * @returns the minimum vertical angle that can be displayed by this pano in radians
	 * @defaultValue -Pi/2 (straight down, render everything)
	 */
	public get minTheta(): number {
		return this.material.minTheta;
	}
	/**
	 * sets the minimum vertical angle that can be displayed by this pano in radians
	 * can be used to not render undesirable artifacts from the bottom of the pano "sphere"
	 *
	 * @defaultValue -Pi/2 (straight down, render everything)
	 */
	public set minTheta(value: number) {
		this.material.minTheta = value;
	}

	/** @returns true if animating */
	get animating(): boolean {
		return this.#animating;
	}

	/** Change animating status */
	set animating(a: boolean) {
		this.#animating = a;
		if (!a && this.#pendingTile) {
			this.#overviewTile.setTile(this.#pendingTile)?.dispose();
			this.textureChanged.emit();
			this.#pendingTile = undefined;
		}
	}

	/**
	 * Add a new tile to this pano
	 *
	 * @param texture The texture for the new tile
	 * @param rect The rect covered in uv coordinates (col, row, width, height)
	 * @param depth The depth of this tile in the tree
	 */
	addTile(texture: Texture, rect: Box2, depth: number): void {
		const t = new PanoTile(this.material, texture, rect);
		// Depth +1 because the overview tile is zero and all our tiles should be on top of that
		t.renderOrder = depth + 1;
		this.add(t);
		this.textureChanged.emit();

		this.#textures.push(texture);
	}

	/**
	 * Replace all the current tiles with a single one covering the entire pano surface
	 *
	 * @param texture The new full sphere tile
	 */
	replaceOverview(texture: Texture): void {
		if (this.showDepthImage) return;
		this.name = `TiledPano ${texture.name || texture.uuid}`;
		this.#textures.push(texture);
		if (this.#animating && texture.image.width > 1024) {
			this.#pendingTile = texture;
		} else {
			this.#overviewTile.setTile(texture)?.dispose();
			this.textureChanged.emit();
		}
	}

	/**
	 * Remove one tile from this pano
	 *
	 * @param texture The tile we want to remove
	 */
	removeTile(texture: Texture): void {
		if (texture === this.#overviewTile.texture) return;
		for (const ts of this.children) {
			if (ts.texture === texture) {
				this.remove(ts);
				ts.dispose();
				this.textureChanged.emit();
				return;
			}
		}
	}

	/**
	 * Check if a tile is currently part of this TiledPano
	 *
	 * @param texture the tile to check
	 * @returns true if this tile is in the current rendering list
	 */
	hasTile(texture: Texture): boolean {
		if (texture === this.#overviewTile.texture || texture === this.#depthImage) return true;
		return this.children.find((tile) => tile.texture === texture) !== undefined;
	}

	/**
	 * Attach a depth image for depths computations
	 *
	 * @param depths The depth image
	 */
	setDepths(depths: EquirectangularDepthImage): void {
		this.depths = depths;
		this.depths.setPanoWorldMatrix(this.matrixWorld);
		this.#computeDepthImage();
		this.depthsChanged.emit();
	}

	/** @returns the pano width */
	get width(): number {
		return this.#overviewTile.texture?.image.width ?? 0;
	}

	/** @returns the pano height */
	get height(): number {
		return this.width / 2;
	}

	/** @returns the depths width */
	get depthWidth(): number {
		return this.depths?.image.width ?? 0;
	}

	/** @returns true if we have depth information */
	get hasDepths(): boolean {
		return this.depths !== undefined;
	}

	/** @returns the current pano opacity */
	get opacity(): number {
		return this.material.opacity;
	}

	/** Change the pano opacity */
	set opacity(opacity: number) {
		this.material.opacity = opacity;
		this.material.transparent = opacity !== 1;
		this.material.uniformsNeedUpdate = true;
	}

	/** @inheritdoc */
	raycast(raycaster: Raycaster, intersects: Array<Intersection<Object3D>>): void {
		if (!this.depths) return;
		return this.depths.raycast(this, raycaster, intersects);
	}

	/** @returns the root tile of the tile tree. */
	get overviewTile(): PanoTile {
		return this.#overviewTile;
	}

	/**
	 * @param s Whether to show the depth image or not
	 */
	set showDepthImage(s: boolean) {
		if (!this.depths) return;
		assert(this.#depthImage);
		if (s) {
			this.#colorOverviewImage = this.#overviewTile.texture;
			this.#overviewTile.setTile(this.#depthImage);
		} else if (this.#colorOverviewImage) {
			this.#overviewTile.setTile(this.#colorOverviewImage);
		}
	}

	/** @returns whether the depth image is being shown. */
	get showDepthImage(): boolean {
		return this.#overviewTile.texture === this.#depthImage;
	}
}
