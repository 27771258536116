import { FilteredRenderPass as LotvFilteredRenderPass } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { forwardRef, useEffect, useState } from "react";
import { Object3D } from "three";
import { TypedEventCallback, useTypedEvent } from "../../hooks";
import { attachPass } from "../attach-utils";

export type FilteredRenderPassProps = {
  /** The function used to filter the scene */
  filter(o: Object3D): boolean;
  /** True if the color buffer must be clear before rendering */
  clear?: boolean;
  /** True if the depth buffer must be clear before rendering */
  clearDepth?: boolean;
  /** Callback to be called just before the pass is rendering */
  onBeforeRender?: TypedEventCallback<LotvFilteredRenderPass["beforeRender"]>;
  /** Make the background of the framebuffer completely transparent */
  transparentBackground?: boolean;
};

export type FilteredRenderPassRef = LotvFilteredRenderPass | undefined;

/** @returns A pass to render a filtered version of the input scene*/
export const FilteredRenderPass = forwardRef<
  FilteredRenderPassRef,
  FilteredRenderPassProps
>(function FilteredRenderPass(
  {
    filter,
    clear,
    clearDepth,
    onBeforeRender,
    transparentBackground = false,
  }: FilteredRenderPassProps,
  ref,
): JSX.Element {
  const scene = useThree((s) => s.scene);
  const camera = useThree((s) => s.camera);

  const [pass] = useState(
    () => new LotvFilteredRenderPass(scene, camera, filter, clear, clearDepth),
  );
  pass.transparentBackground = transparentBackground;

  useEffect(() => {
    pass.camera = camera;
  }, [pass, camera]);

  useTypedEvent(pass.beforeRender, onBeforeRender);

  return (
    <primitive
      ref={ref}
      name="FilteredRenderPass"
      object={pass}
      attach={attachPass}
    />
  );
});
