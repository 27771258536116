import { useEffect, useState } from "react";
import { ColorRepresentation } from "three";
import { ClearPass as ThreeClearPass } from "three-stdlib";
import { attachPass } from "../attach-utils";

export type ClearPassProps = {
  /**
   * The clear color to be used
   *
   * @default white
   */
  clearColor?: ColorRepresentation;

  /**
   * Opacity of the selected clear color
   *
   * @default 1.0
   */
  clearAlpha?: number;

  /**
   * Whether the pass is enabled
   *
   * @default true
   */
  enabled?: boolean;
};

/**
 * @returns An EffectPipeline pass that clears the output FBO with the given clear color.
 */
export function ClearPass({
  clearColor = "white",
  clearAlpha = 1.0,
  enabled = true,
}: ClearPassProps): JSX.Element {
  const [pass] = useState(() => new ThreeClearPass());

  useEffect(() => {
    pass.enabled = enabled;
  }, [pass, enabled]);

  useEffect(() => {
    pass.clearColor = clearColor;
  }, [pass, clearColor]);

  useEffect(() => {
    pass.clearAlpha = clearAlpha;
  }, [pass, clearAlpha]);

  return <primitive object={pass} attach={attachPass} />;
}
