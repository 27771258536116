import {
  GapFillOptimizedFor,
  GapFillPass as LotvWSGapFillPass,
  MipGapFillPass,
} from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { Camera } from "three";
import { attachPass } from "../attach-utils";

export type GapFillPassProps = {
  /** Whether the pass is enabled */
  enabled?: boolean;
  /** The camera to use to compute the effect */
  camera?: Camera;
  /** The optimization strategy for the gap filling, speed or gap */
  optimizedFor?: GapFillOptimizedFor;
};

/**
 * @returns A EffectPipeline pass that will apply the mip gap filling filter to the entire scene
 */
export function GapFillPass({
  enabled = true,
  camera,
  optimizedFor = GapFillOptimizedFor.GapSize,
}: GapFillPassProps): JSX.Element {
  const defaultCamera = useThree((s) => s.camera);
  const effectCamera = camera ?? defaultCamera;

  const [pass] = useState(() => new MipGapFillPass(effectCamera, optimizedFor));

  useEffect(() => {
    pass.camera = effectCamera;
  }, [pass, effectCamera]);

  useEffect(() => {
    pass.optimizedFor = optimizedFor;
  }, [pass, optimizedFor]);

  return (
    <primitive
      object={pass}
      attach={attachPass}
      camera={effectCamera}
      enabled={enabled}
    />
  );
}

export type WSGapFillPassProps = {
  /** The camera to use to compute the effect */
  camera?: Camera;
};

/**
 * @returns a pass that implements the Webshare gap filling algorithm
 */
export function WSGapFillPass({ camera }: WSGapFillPassProps): JSX.Element {
  const defaultCamera = useThree((s) => s.camera);
  const effectCamera = camera ?? defaultCamera;

  const [pass] = useState(() => new LotvWSGapFillPass(effectCamera));

  useEffect(() => {
    pass.camera = effectCamera;
  }, [pass, effectCamera]);

  return <primitive object={pass} attach={attachPass} />;
}
