import { SsaoPass as LotvSsaoPass } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { Camera } from "three";
import { attachPass } from "../attach-utils";

export type SSAOPassProps = {
  /** Whether this step is enabled or not */
  enabled?: boolean;
  /** The camera to use to compute the effect */
  camera?: Camera;
  /** SSAO strength*/
  strength?: number;
  /** SSAO radius*/
  radius?: number;
  /** SSAO bias*/
  bias?: number;
};

/**
 * @returns A EffectPipeline pass that will apply SSAO to the entire scene
 * The default values given to the parameters strength, radius and bias ensure that
 * the corners of a room are smoothly shaded with plausible AO.
 */
export function SSAOPass({
  enabled = true,
  camera,
  strength = 1,
  radius = 0.5,
  bias = 0.025,
}: SSAOPassProps): JSX.Element {
  const defaultCamera = useThree((s) => s.camera);
  const effectCamera = camera ?? defaultCamera;

  const [pass] = useState(() => new LotvSsaoPass(effectCamera));

  useEffect(() => {
    pass.camera = effectCamera;
  }, [pass, effectCamera]);

  useEffect(() => {
    pass.strength = strength;
  }, [pass, strength]);

  useEffect(() => {
    pass.radius = radius;
  }, [pass, radius]);

  useEffect(() => {
    pass.bias = bias;
  }, [pass, bias]);

  return (
    <primitive
      name="SSAOPass"
      object={pass}
      attach={attachPass}
      enabled={enabled}
    />
  );
}
