import { ComposeFramebuffersPass as LotvComposeFramebuffersPass } from "@faro-lotv/lotv";
import { Color as ColorProp, useThree } from "@react-three/fiber";
import { PropsWithChildren, forwardRef, useEffect, useState } from "react";
import { parseColor } from "../../utils/props";
import { attachPass } from "../attach-utils";

export type ComposeFramebuffersPassProps = PropsWithChildren<unknown> & {
  /** An optional color that will override the output of previous passes */
  background?: ColorProp | null;
  /** Opacity to blend the first FBO in the result */
  opacity1?: number;
  /** Opacity to blend the second FBO in the result */
  opacity2?: number;
  /** Opacity to blend the third FBO in the result */
  opacity3?: number;
  /** If true, FBO1 is rendered with a color whose hue maps the pixel to pixel distance from FBO2, in meters */
  thermoCompare?: boolean;
  /** Max distance that is mapped to hue when thermoCompare is true */
  thermoThreshold?: number;
  /** A depth offset in meters that is optionally added to FBO 2 */
  scene2DepthOffset?: number;
  /** If true, enables an experimental blending mode in which FBO1 is assigned a hue and FBO2 another hue. */
  falseColors?: boolean;
  /** True if the buffer should be rendered to the screen instead of the FBO */
  renderToScreen?: boolean;
};

export type ComposeFramebuffersPassRef =
  | LotvComposeFramebuffersPass
  | undefined;

/** @returns A pass who is able to compose two subscenes in a variety of ways */
export const ComposeFramebuffersPass = forwardRef<
  ComposeFramebuffersPassRef,
  ComposeFramebuffersPassProps
>(function ComposeFramebuffersPass(
  {
    children,
    background = null,
    opacity1 = 1.0,
    opacity2 = 1.0,
    opacity3 = 1.0,
    thermoCompare = false,
    thermoThreshold = 1.0,
    scene2DepthOffset = 0.0,
    falseColors = false,
    renderToScreen = false,
  }: ComposeFramebuffersPassProps,
  ref,
): JSX.Element {
  const { camera } = useThree();

  const [pass] = useState(
    () =>
      new LotvComposeFramebuffersPass(
        camera,
        background ? parseColor(background) : null,
      ),
  );

  useEffect(() => {
    pass.camera = camera;
  }, [pass, camera]);

  useEffect(() => {
    pass.opacity1 = opacity1;
  }, [pass, opacity1]);

  useEffect(() => {
    pass.opacity2 = opacity2;
  }, [pass, opacity2]);

  useEffect(() => {
    pass.opacity3 = opacity3;
  }, [pass, opacity3]);

  useEffect(() => {
    pass.thermoCompare = thermoCompare;
  }, [pass, thermoCompare]);

  useEffect(() => {
    pass.thermoThreshold = thermoThreshold;
  }, [pass, thermoThreshold]);

  useEffect(() => {
    pass.scene2DepthOffset = scene2DepthOffset;
  }, [pass, scene2DepthOffset]);

  useEffect(() => {
    pass.falseColors = falseColors;
  }, [pass, falseColors]);

  useEffect(() => {
    pass.renderToScreen = renderToScreen;
  }, [pass, renderToScreen]);

  useEffect(() => {
    pass.backgroundColor = background ? parseColor(background) : null;
  }, [pass, background]);

  return (
    <primitive object={pass} ref={ref} attach={attachPass}>
      {children}
    </primitive>
  );
});
